<template>
  <div>
    <Map :chartData="chartData"></Map>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartData: []
    };
  }
};
</script>

<style></style>
